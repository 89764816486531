// import { IXdocPlanning } from "@/types/placeOrderTypes";

export interface IBusinessFormMap {
  [key: string]: IServiceMap;
}
export interface IServiceMap {
  fileType: string[];
  category: string;
  name?: string;
  mode?: 'single' | 'selected';
  formData: {
    service_content: number;
    material?: {
      type: number;
    };
    xdoc_planning?: any;
  };
}
export const businessFormMap: IBusinessFormMap = {
  // formData.material.type 0:FBD 1:word 2:图片 3:pdf 4:ppt
  // 可编辑课件制作
  editableCourseWare: {
    fileType: ['zip', 'pdf', 'word'],
    category: 'courseware',
    formData: {
      service_content: 0,
      material: {
        type: 0
      },
    }
  },
  screenShotCourseWare: {
    fileType: ['pdf'],
    category: 'courseware',
    formData: {
      service_content: 1,
      material: {
        type: 3
      },
    }
  },
  imgToDocx: {
    fileType: ['img'],
    category: 'dataConversion',
    formData: {
      service_content: 2,
      material: {
        type: 2
      }
    }
  },
  imgToFbd: {
    fileType: ['img'],
    category: 'dataConversion',
    formData: {
      service_content: 4,
      material: {
        type: 2
      }
    }
  },
  fbdToWord: {
    fileType: ['zip', 'pdf'],
    category: 'dataConversion',
    formData: {
      service_content: 3,
      material: {
        type: 0,
      },
    }
  },
  wordToFbd: {
    fileType: ['word'],
    name: 'WORD2FBD',
    category: 'dataConversion',
    mode: 'selected',
    formData: {
      service_content: 11,
      material: {
        type: 5,
      },
    }
  },
  wordToFbd_auto: {
    fileType: ['word'],
    name: 'WORD2FBD',
    category: 'dataConversion',
    mode: 'selected',
    formData: {
      service_content: 16,
      material: {
        type: 5,
      },
    }
  },
  pdfToDocx: {
    fileType: ['pdf'],
    category: 'dataConversion',
    formData: {
      service_content: 9,
      material: {
        type: 3,
      },
    }
  },
  questionBank: {
    fileType: ['zip', 'pdf', 'img', 'word'],
    mode: 'selected',
    category: 'digitization',
    formData: {
      service_content: 5
    }
  },
  studentToTeacher: {
    fileType: ['zip'],
    name: 'STU2TEA',
    category: 'studentToTeacher',
    formData: {
      service_content: 10,
      material: {
        type: 4,
      },
    }
  },
  colourImageToMonochromeImage: {
    fileType: [],
    category: 'processImage',
    formData: {
      service_content: 12,
      material: {
        type: 6,
      },
    }
  },
  blackImageToMonochromeImage: {
    fileType: [],
    category: 'processImage',
    formData: {
      service_content: 13,
      material: {
        type: 7,
      },
    }
  },
  teacherImageLineColor: {
    fileType: [],
    category: 'processImage',
    formData: {
      service_content: 14,
      material: {
        type: 8,
      },
    }
  },
  imageResolvingPower: {
    fileType: [],
    category: 'processImage',
    formData: {
      service_content: 15,
      material: {
        type: 9,
      },
    }
  },
  imgClarity: {
    fileType: ['img'],
    category: 'dataConversion',
    formData: {
      service_content: 17,
      material: {
        type: 10
      }
    }
  },
  wordToPpt_auto: {
    fileType: ['word'],
    name: 'WORD2PPT_AUTO',
    category: 'dataConversion',
    mode: 'selected',
    formData: {
      service_content: 18,
      material: {
        type: 11,
      },
    }
  },
  pptToEnbx: {
    fileType: ['zip'],
    category: 'pptUpload',
    formData: {
      service_content: 25,
      material: {
        type: 16,
      },
    }
  },
};
